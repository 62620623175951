@import url(https://fonts.googleapis.com/css?family=Work+Sans);
@import url(//hello.myfonts.net/count/381000);
@font-face {
  font-family: 'HalisR-Bold';
  src: url('/fonts/381000_0_0.eot');
  src: url('/fonts/381000_0_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_0_0.woff2') format('woff2'),
    url('/fonts/381000_0_0.woff') format('woff'),
    url('/fonts/381000_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR';
  src: url('/fonts/381000_1_0.eot');
  src: url('/fonts/381000_1_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_1_0.woff2') format('woff2'),
    url('/fonts/381000_1_0.woff') format('woff'),
    url('/fonts/381000_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR-Medium';
  src: url('/fonts/HalisR-Medium.eot');
  src: url('/fonts/HalisR-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisR-Medium.woff2') format('woff2'),
  url('/fonts/HalisR-Medium.woff') format('woff'),
  url('/fonts/HalisR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisR-Book';
  src: url('/fonts/HalisR-Book.eot');
  src: url('/fonts/HalisR-Book.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisR-Book.woff2') format('woff2'),
  url('/fonts/HalisR-Book.woff') format('woff'),
  url('/fonts/HalisR-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'VeneerClean';
  src: url('/fonts/381000_2_0.eot');
  src: url('/fonts/381000_2_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/381000_2_0.woff2') format('woff2'),
    url('/fonts/381000_2_0.woff') format('woff'),
    url('/fonts/381000_2_0.ttf') format('truetype');
}

@font-face {
  font-family: BPreplay;
  src: url('/fonts/BPreplay/BPreplay.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-weight: bold;
  src: url('/fonts/BPreplay/BPreplayBold.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-style: italic;
  font-weight: bold;
  src: url('/fonts/BPreplay/BPreplayBoldItalics.otf') format('opentype');
}

@font-face {
  font-family: BPreplay;
  font-style: italic;
  src: url('/fonts/BPreplay/BPreplayItalics.otf') format('opentype');
}

html {
  box-sizing: border-box;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'HalisR-Medium', 'Work Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #212840;
}
h1,
h2,
h3 {
  font-family: 'VeneerClean', sans-serif;
  line-height: 1;
  font-weight: normal;
}
#root {
  height: 100%;
}
p {
  margin: 0;
}
form {
  width: 100%;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
.ReactModal__Body--open {
  overflow: hidden;
}

.slick-prev {
  left: 25px;
  z-index: 1;
}

.slick-next {
  right: 25px;
}

.slick-dots li {
  margin: 0;
}
.slick-dots li.slick-active button:before {
  color: #fabe79;
}

.slick-dots li button:before {
  font-size: 10px;
  opacity: 1;
  color: #212840;
}

.slick-dots li button:hover {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: .5;
}

input {
  font-family: HalisR-Medium;
}

::-webkit-input-placeholder {
  font-family: HalisR-Medium;
}

::-ms-input-placeholder {
  font-family: HalisR-Medium;
}

::placeholder {
  font-family: HalisR-Medium;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

